//
// Reboot
// --------------------------------------------------

// Import Google Fonts (Inter)
@import url($path-to-fonts);

html * {
  @include font-smoothing;
}

// Sticky footer
html, body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.cs-page-wrapper {
  flex: 1 0 auto;
}
.cs-footer {
  flex-shrink: 0;
}


// Links
a {
  transition: $link-transition;
  &:focus { outline: none; }
}

// Images
// Responsive images (ensure images don't scale beyond their parents)
img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg { max-width: 100%; }

// Responsive iframes
iframe {
  width: 100%;
  border: 0;
}

// Horizontal rule
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $border-color;
  &.hr-light { border-color: $border-light-color; }
}

// Text Selection Color
::selection {
  background: $user-selection-color;
}
::-moz-selection {
  background: $user-selection-color;
}

// Change Bootstrap default .container-fluid paddings
@include media-breakpoint-up(xl) {
  .container-fluid {
    padding-right: $grid-gutter-width;
    padding-left: $grid-gutter-width;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

small { font-weight: inherit; }

// Disable button :focus outline
button:focus {
  outline: none;
}
